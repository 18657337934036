import React, { useEffect } from 'react';
// import PropTypes from 'prop-types';
import styles from './FindMugPage.module.scss';
import Hero from '../../components/Hero/Hero';
import Socials from '../../components/Socials/Socials';
// import Destini from '../../components/Destini/Destini';

const FindMugPage = () => {

useEffect(() => {
  const scriptTag = document.createElement('script');
  scriptTag.src = '//destinilocators.com/pepsicrossbrand/a/install/?ACODE=lite1&WLAB=mug'
  document.body.appendChild(scriptTag)
}, [])


return (
  <main id="main" className={styles.FindMugPage} data-testid="FindMugPage">
    <Hero text="Find Mug" />
      <div className={styles.container}>
        <div id="destini_container" ></div>
      </div>
    <Socials />
  </main>
)}

FindMugPage.propTypes = {};

FindMugPage.defaultProps = {};

export default FindMugPage;

import React from 'react';
import PropTypes from 'prop-types';
import styles from './RecipePage.module.scss';
import Hero from '../../components/Hero/Hero';
import Recipe from '../../components/Recipe/Recipe';
import recipes from './../../lib/recipes.json';
import Socials from '../../components/Socials/Socials';

const RecipePage = () => (
  <main id="main" className={styles.RecipePage} data-testid="RecipePage">
    <Hero text="recipes" />
    <div className={styles.recipes}>
      {recipes.map(recipe => (
        <Recipe key={recipe.id} recipe={recipe} />
    ))}
    </div>
    <Socials />
  </main>
);

RecipePage.propTypes = {};

RecipePage.defaultProps = {};

export default RecipePage;

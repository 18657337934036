import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './Header.module.scss';
import logo from './../../assets/template/m-s-header-logo-mug@2x.png'
import Button from './../../partials/Button/Button'
import { NAV } from '../../lib/constants';
import { Hamburger} from '../../partials/Icons/Icons';
import gsap from 'gsap';


const Header = ({setisMenuOpen, isMenuOpen}) => {
  const handleClick = (e) => setisMenuOpen(true);
  
  return (
    <header className={styles.Header} data-testid="Header">
      <button className={styles.hamburger} onClick={(e)=>handleClick(e)} aria-label={`menu ${isMenuOpen ? "opened" : "collapsed"}`} tabIndex={isMenuOpen ? "-1" : "0"}>
        <Hamburger />
      </button>
      <Link to='/' className={styles.logo} aria-label='Home' tabIndex={isMenuOpen ? "-1" : "0"}>
        <img src={logo} alt='logo' />
      </Link>   
      {!isMenuOpen && <div className={styles.linksContainer}>
        {NAV.slice(0,1).map(link => (
          <Link key={link.TITLE} className={styles.link} to={link.URI} tabIndex={isMenuOpen ? "-1" : "0"}>
            {link.TITLE}
          </Link>
        ))
        }
        <Button to={NAV[NAV.length - 1].URI} className={styles.findMug} tabIndex={isMenuOpen ? "-1" : "0"}>
          {NAV[NAV.length - 1].TITLE}
        </Button>
      </div>}

    </header>
  )
};

Header.propTypes = {
  setisMenuOpen: PropTypes.func
};

Header.defaultProps = {

};

export default Header;


import styles from "./Icons.module.scss"

export const TikTok = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25.226" height="29.402" viewBox="0 0 25.226 29.402">
    <defs>
        <clipPath id="lhbnexr7ea">
            <path data-name="Rectangle 115" css="fill:#fa0" d="M0 0h25.226v29.402H0z"/>
        </clipPath>
    </defs>
    <g data-name="Group 232" css="clip-path:url(#lhbnexr7ea)">
        <path data-name="Path 145" d="M18.364 0c.5 4.181 2.828 6.669 6.862 6.924V11.6c-2.347.227-4.4-.566-6.789-1.98v8.78c0 11.167-12.173 14.646-17.055 6.653-3.146-5.143-1.217-14.188 8.87-14.54v4.962a15.474 15.474 0 0 0-2.332.566c-2.262.752-3.507 2.183-3.156 4.689.684 4.8 9.492 6.223 8.757-3.157V.017h4.865z" css="fill-rule:evenodd"/>
    </g>
</svg>
);

export const Instagram = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="26.974" height="26.974" viewBox="0 0 26.974 26.974">
      <defs>
          <clipPath id="vzkpot1jha">
              <path data-name="Rectangle 116" css="fill:none" d="M0 0h26.974v26.974H0z"/>
          </clipPath>
      </defs>
      <g data-name="Group 234" css="clip-path:url(#vzkpot1jha)">
          <path data-name="Path 146" d="M13.487 2.427c3.592 0 4.022 0 5.442.079a7.284 7.284 0 0 1 2.5.464 4.475 4.475 0 0 1 2.552 2.55 7.3 7.3 0 0 1 .464 2.5c.068 1.42.079 1.849.079 5.442s0 4.022-.079 5.442a7.284 7.284 0 0 1-.464 2.5 4.473 4.473 0 0 1-2.552 2.552 7.313 7.313 0 0 1-2.5.464c-1.42.068-1.843.079-5.442.079s-4.022 0-5.442-.079a7.313 7.313 0 0 1-2.5-.464 4.473 4.473 0 0 1-2.552-2.552 7.284 7.284 0 0 1-.464-2.5c-.068-1.42-.079-1.849-.079-5.442s0-4.022.079-5.442a7.3 7.3 0 0 1 .464-2.5 4.475 4.475 0 0 1 2.552-2.55 7.284 7.284 0 0 1 2.5-.464c1.42-.068 1.849-.079 5.442-.079m0-2.427c-3.655 0-4.113 0-5.55.079a9.913 9.913 0 0 0-3.269.628 6.846 6.846 0 0 0-3.961 3.96 9.925 9.925 0 0 0-.628 3.27C.017 9.374 0 9.832 0 13.487s0 4.113.079 5.55a9.918 9.918 0 0 0 .628 3.269 6.848 6.848 0 0 0 3.961 3.961 9.913 9.913 0 0 0 3.269.628c1.437.062 1.895.079 5.55.079s4.113 0 5.55-.079a9.913 9.913 0 0 0 3.269-.628 6.848 6.848 0 0 0 3.961-3.961 9.918 9.918 0 0 0 .628-3.269c.062-1.437.079-1.895.079-5.55s0-4.113-.079-5.55a9.925 9.925 0 0 0-.628-3.271A6.846 6.846 0 0 0 22.306.707a9.913 9.913 0 0 0-3.27-.628C17.6.017 17.141 0 13.487 0"/>
          <path data-name="Path 147" d="M12.408 5.471a6.913 6.913 0 1 0 6.913 6.913 6.913 6.913 0 0 0-6.913-6.913m0 11.4a4.486 4.486 0 1 1 4.486-4.486 4.486 4.486 0 0 1-4.486 4.485z" transform="translate(1.079 1.074)"/>
          <path data-name="Path 148" d="M17.545 3.892a1.618 1.618 0 1 1-1.617 1.617 1.618 1.618 0 0 1 1.617-1.617" transform="translate(3.126 .764)"/>
      </g>
  </svg>
);

export const Twitter = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="27.443" height="25.684" viewBox="0 0 27.443 25.684">
      <path data-name="Path 149" d="M3.6 1.768h3.748l16.564 22.149h-3.75zM23.775 0l-8.6 9.287L8.233 0H.067l10.6 14.166L0 25.684h2.4L11.734 15.6l7.543 10.083h8.166L16.251 10.721 26.175 0z"/>
  </svg>
)

export const Treads = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="26.332" height="30.607" viewBox="0 0 26.332 30.607">
    <defs>
        <clipPath id="cip20th6sa">
            <path data-name="Rectangle 117" css="fill:none" d="M0 0h26.332v30.607H0z"/>
        </clipPath>
    </defs>
    <g data-name="Group 236" css="clip-path:url(#cip20th6sa)">
        <path data-name="Path 150" d="M20.491 14.187q-.2-.1-.4-.183c-.236-4.353-2.614-6.845-6.608-6.87h-.054a6.454 6.454 0 0 0-5.6 2.875l2.2 1.507a3.892 3.892 0 0 1 3.4-1.682h.036a3.735 3.735 0 0 1 2.95 1.136 4.484 4.484 0 0 1 .934 2.239 16.889 16.889 0 0 0-3.774-.182c-3.8.219-6.239 2.434-6.075 5.511a4.745 4.745 0 0 0 2.189 3.782 6.755 6.755 0 0 0 4.075 1.022 6.018 6.018 0 0 0 4.631-2.251 8.346 8.346 0 0 0 1.578-4.134 4.883 4.883 0 0 1 2.035 2.226 5.556 5.556 0 0 1-1.364 6.117c-1.8 1.8-3.973 2.583-7.251 2.607-3.637-.026-6.386-1.193-8.174-3.466-1.675-2.128-2.54-5.2-2.572-9.137.032-3.935.9-7.009 2.572-9.137C7.008 3.894 9.757 2.728 13.394 2.7c3.662.026 6.46 1.2 8.317 3.482a11.323 11.323 0 0 1 2.049 4.17l2.573-.687a13.925 13.925 0 0 0-2.586-5.205C21.368 1.531 17.887.031 13.4 0h-.018C8.909.031 5.467 1.537 3.155 4.476 1.1 7.092.036 10.732 0 15.293v.022c.035 4.561 1.1 8.2 3.153 10.817 2.312 2.939 5.754 4.445 10.229 4.476h.018c3.979-.028 6.784-1.069 9.094-3.378a8.281 8.281 0 0 0 1.936-9.13 7.813 7.813 0 0 0-3.941-3.913m-6.87 6.459c-1.664.093-3.394-.653-3.479-2.254-.063-1.186.845-2.51 3.58-2.668q.47-.027.924-.026a13.049 13.049 0 0 1 2.769.281c-.316 3.938-2.165 4.577-3.795 4.667" />
    </g>
</svg>
)


export const Hamburger = () => (
  <svg xmlns="http://www.w3.org/2000/svg" className={styles.Hamburger} width="37" height="36" viewBox="0 0 37 36">
    <path data-name="Line 1" transform="translate(0 29)" d="M0 0h37"/>
    <path data-name="Line 2" transform="translate(0 18)" d="M0 0h37"/>
    <path data-name="Line 3" transform="translate(0 7)" d="M0 0h37"/>
</svg>
)
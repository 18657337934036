export const NAV = [
    {TITLE: "About Mug", URI: "/about"},
    // {TITLE: "Recipes", URI: "/recipes"},
    {TITLE: "Find Mug", URI: "/find-mug"},
  ]
  
  
  export const HOME = {
    TITLE: "GOT THAT<br><span>Dawg</span><br>IN YOU?"
  }
  
  
  export const ABOUT = {
    TITLE: "About Mug",
    TITLE_2: "About Dog",
    BODY: "Indulge in the delicious taste, rich foam, and unique aroma of MUG Root Beer. The caffeine-free soft drink is a satisfying ice-cold refreshment that can be enjoyed on its own or as a complement to any meal. ​MUG Root Beer is also available in MUG Zero Sugar – boasting the same signature taste, now with zero sugar (and zero calories).",
    BODY_DOG: "Dog is more than just a mascot, he’s the beloved face of the MUG Root Beer brand. Solely focused on one main mission of seeking satisfaction in the form of MUG Root Beer, Dog is bold and determined, but also loves to relax and socialize with people. Despite his gruff, strong exterior, Dog is an emotional character who wants to feel comfortable and loved. Stay tuned for more on Dog’s journey!",
    TAGLINE: "GOT THAT DAWG IN YOU?"
  }
  
  
  export const FOOTER = {
    LINKS: [
      { TITLE: "CONTACT US", URI: "https://contact.pepsico.com/mugrootbeer" },
      { TITLE: "About Us", URI: "https://contact.pepsico.com/mugrootbeer/about-us" },
      { TITLE: "Terms of Use", URI: "https://contact.pepsico.com/mugrootbeer/terms-conditions" },
      { TITLE: "PRODUCT FACTS", URI: "https://www.pepsicoproductfacts.com/" },
      { TITLE: "PRIVACY POLICY", URI: "https://contact.pepsico.com/mugrootbeer/privacy-policy" },
      { TITLE: "STORE LOCATOR", URI: "https://contact.pepsico.com/mugrootbeer/product-locator" },
      { TITLE: "Accessibility Statement", URI:"https://contact.pepsico.com/mugrootbeer/accessibility-statement"},
    ]
  }
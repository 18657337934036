import React from 'react';
import { 
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import './App.css';
import MainPage from './template/MainPage/MainPage';
import HomePage from './pages/HomePage/HomePage';
import AboutPage from './pages/AboutPage/AboutPage';
import RecipePage from './pages/RecipePage/RecipePage';
import FindMugPage from './pages/FindMugPage/FindMugPage';
import PageNotFound from './pages/PageNotFound/PageNotFound';


function App() {
  return (
    <div className="App">
    <Router>
      <MainPage>
          <Routes>
            <Route path='/' Component={ HomePage } />
            <Route path='/about' Component={ AboutPage } />
            <Route path='/recipes' Component={ RecipePage } />
            <Route path='/find-mug' Component={ FindMugPage } />
            <Route path='*' Component={ PageNotFound } />
          </Routes>
      </MainPage>
      </Router>
    </div>
  );
}

export default App;

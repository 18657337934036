import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from './Footer.module.scss';
import logo from './../../assets/template/m-s-header-logo-mug@2x.png'
import Socials from './../Socials/Socials';
import { FOOTER } from './../../lib/constants'

const Footer = () => (
  <footer className={styles.Footer} data-testid="Footer">
    <div className={styles.container}>
      <div className={styles.logo}>
      <Link to="/" aria-label="home" >
        <img src={logo} alt='' />
      </Link>
      </div>
      <div className={styles.LegalLink}>
        <ul>
          <LegalLinks />
        </ul>
      </div>
      <div>
        <Socials className={styles.Socials} title=""/>
        
        <div className={styles.CookiesLink} id="teconsent"></div>
      </div>
    </div>
  </footer>
);

export const LegalLinks = () => (
    FOOTER.LINKS?.map(link => (
      <li key={link.TITLE}>
        <a href={link.URI}>{link.TITLE}</a>
      </li>
    ))
  )


Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;

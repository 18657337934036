import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import styles from './HomePage.module.scss';
import Socials from './../../components/Socials/Socials';
import Hero from '../../components/Hero/Hero';
import { HOME } from '../../lib/constants';
import about from './../../assets/home/about-mug-btn@2x.png'
import find from './../../assets/home/find-mug-btn@2x.png'
import recipes from './../../assets/home/recipes-btn@2x.png'
import { Link } from 'react-router-dom';
import gsap from 'gsap';
import DOMPurify from 'dompurify';

const HomePage = () => {
  const linksContainer = useRef()
  const sanitizeConst = (htmlConst) => DOMPurify.sanitize(htmlConst)
  
  useEffect(() => {
    let anim = gsap.fromTo(linksContainer.current.children, {
      duration: 1,
      opacity: 0,
      y: "100px",
    },
    {
      opacity: 1,
      y: 0,
      ease: "elastic.out(1,0.3)",
      delay: 0.5,
      stagger: 0.5
    }
    
    )
  }, [])
  

  return (
    <main id="main" className={styles.HomePage} data-testid="HomePage">
      <Hero text={sanitizeConst(HOME.TITLE)} isTM={true} />
      <div className={styles.container} ref={linksContainer}>
          <Link to="/about" className={styles.about}>
            <img src={about}  alt="about mug" />
          </Link>
          <Link to="/find-mug" className={styles.find}>
            <img src={find}  alt="find mug" />
          </Link>
          {/* <Link to="/recipes" className={styles.recipes}>
            <img src={recipes}  alt="recipes" />
          </Link> */}
      </div>
      <Socials className={styles.Socials}/>
    </main>
  )
};

HomePage.propTypes = {
  
};

HomePage.defaultProps = {

};

export default HomePage;

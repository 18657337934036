import React from 'react';
import PropTypes from 'prop-types';
import styles from './PageNotFound.module.scss';
import Hero from '../../components/Hero/Hero';
import Button from '../../partials/Button/Button';
import Socials from '../../components/Socials/Socials';

const PageNotFound = () => (
  <main id="main" className={styles.PageNotFound} data-testid="PageNotFound">
    <Hero text={"THIS DAWG MUST BE OUT FOR A WALK"} />
    <h2>this page is not available</h2>
    <Button to={"/"} className={styles.Button}>
      Take Me Home
    </Button>
    <Socials />
  </main>
);

PageNotFound.propTypes = {};

PageNotFound.defaultProps = {};

export default PageNotFound;

import React from 'react';
import PropTypes from 'prop-types';
import styles from './Hero.module.scss';
import DOMPurify from 'dompurify';

const Hero = ({text, isTM}) => (
  <h1 className={`${styles.Hero} ${isTM ? styles.TM : ""}`} data-testid="Hero" dangerouslySetInnerHTML={{__html: text}} />
);

Hero.propTypes = {
  text: PropTypes.string
};

Hero.defaultProps = {
  text: "Hero Text"
};

export default Hero;

import React from 'react';
import PropTypes from 'prop-types';
import styles from './MobileMenu.module.scss';
import Socials from '../Socials/Socials';
import logo from './../../assets/template/m-s-header-logo-mug@2x.png'
import { Link } from 'react-router-dom';
import { NAV } from '../../lib/constants';

const MobileMenu = ({isMenuOpen, setisMenuOpen}) => {
  const handleClick = (e) => setisMenuOpen(false);
  
  return (
    <div className={`${styles.MobileMenu} ${(isMenuOpen) ? styles.open : ""}`} data-testid="MobileMenu">
      <div className={styles.header}>
        <button className={styles.close} onClick={handleClick} aria-label='Close' tabIndex={isMenuOpen ? "0" : "-1"}></button>
        <Link to='/' className={styles.logo} onClick={handleClick} aria-label='Home' tabIndex={isMenuOpen ? "0" : "-1"}>
          <img src={logo} alt='logo' />
        </Link>
      </div>
      <nav>         
        {NAV.map(link => (
          <Link key={link.TITLE} to={link.URI} tabIndex={isMenuOpen ? "0" : "-1"} onClick={handleClick}>
            {link.TITLE}
          </Link>
        ))}
        {isMenuOpen && <Socials className={styles.MenuSocials}/>}
      </nav>
    </div>
  )
};

MobileMenu.propTypes = {
  isMenuOpen: PropTypes.bool,
  setisMenuOpen : PropTypes.func
};

MobileMenu.defaultProps = {
  isMenuOpen: false
};

export default MobileMenu;

import React from 'react';
import PropTypes from 'prop-types';
import styles from './Button.module.scss';
import { Link } from 'react-router-dom';

const Button = ({children, className, to}) => (
  <>
    {(to) ?
    <Link className={`${styles.Button} ${className}`} data-testid="Button" to={to}>
      {children}
    </Link>
    :
    <button className={`${styles.Button} ${className}`}>
      {children}
    </button>}
  </>
);

Button.propTypes = {
  title: PropTypes.string,
  to: PropTypes.string,
  className: PropTypes.string,
};

Button.defaultProps = {
  title: "button",
  to: "",
  className: ""
};

export default Button;
import React from 'react';
import PropTypes from 'prop-types';
import styles from './AboutPage.module.scss';
import aboutImg from './../../assets/about/about-mug@2x.png'
import { ABOUT } from '../../lib/constants';
import Socials from '../../components/Socials/Socials';

const AboutPage = () => (
  <main id="main" className={styles.AboutPage} data-testid="AboutPage">
    <div className={styles.container}>
      <div className={styles.imgContainer}>
        <img src={aboutImg} alt=''/>
      </div>
      <div className={styles.copy}>
        <h1>{ABOUT.TITLE}</h1>
        <p>{ABOUT.BODY}</p>
        <h2>{ABOUT.TITLE_2}</h2>
        <p>{ABOUT.BODY_DOG}</p>
        <h3>{ABOUT.TAGLINE}</h3>
      </div>
    </div>
    <Socials />
  </main>
);

AboutPage.propTypes = {};

AboutPage.defaultProps = {};

export default AboutPage;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './MainPage.module.scss';
import Header from "../../components/Header/Header"
import MobileMenu from "../../components/MobileMenu/MobileMenu"
import Footer from "../../components/Footer/Footer"

const MainPage = ({ children }) => {
  const [isMenuOpen, setisMenuOpen] = useState(false)
  
  useEffect(() => {
    const scriptTag = document.createElement('script');
    scriptTag.async = 'async'
    scriptTag.crossOrigin = ''
    scriptTag.src = '//consent.trustarc.com/notice?domain=pepsico-na-beverages.com​&c=teconsent&gtm=1&text=true&js=nj&noticeType=bb&cookieLink=https://www.pepsico.com/legal/privacy'
    document.body.appendChild(scriptTag)
  }, [])
  
  return (
    <div className={styles.MainPage} data-testid="MainPage">
      <a href='#main' tabIndex="0" className={styles['skip-link']}>Skip to Content</a>
      <Header setisMenuOpen={(isOpen)=>setisMenuOpen(isOpen)} isMenuOpen={isMenuOpen} />
      <MobileMenu isMenuOpen={isMenuOpen} setisMenuOpen={(isOpen)=>setisMenuOpen(isOpen)}/>
      {children}
      <Footer />
      <div id="consent_blackbar"></div>​
      <div id="teconsent"></div>​
    </div>
  )
};

MainPage.propTypes = {

};

MainPage.defaultProps = {};

export default MainPage;

import React from 'react';
import PropTypes from 'prop-types';
import styles from './Socials.module.scss';
import {Instagram, TikTok, Treads, Twitter} from '../../partials/Icons/Icons';

const Socials = ({title, className}) => (
  <div className={styles.Socials} data-testid="Socials">
    {(title) ? <h2>{title}</h2> : ""}
    <div className={`${styles.iconContainer} ${className}`}>
      <a href='https://www.tiktok.com/@mugrootbeer?lang=en' aria-label='tiktok'>
        <TikTok />
      </a>
      <a href='https://www.instagram.com/mugrootbeer/?hl=en' aria-label='instagram'>
        <Instagram />
      </a>
      <a href='https://twitter.com/MugRootBeer' aria-label='twitter'>
        <Twitter />
      </a>
      <a href='https://www.threads.net/@mugrootbeer' aria-label='threads'>
        <Treads />
      </a>
    </div>
  </div>
);


Socials.propTypes = {
  title: PropTypes.string
};

Socials.defaultProps = {
  title: "Follow Dog"
};

export default Socials;

import React from 'react';
import PropTypes from 'prop-types';
import styles from './Recipe.module.scss';

const Recipe = ({recipe}) => {

  return (
    <div className={styles.Recipe} data-testid="Recipe">
          <div>
            <img src={require(`./../../assets/recipe/${recipe.image}`)} alt={recipe.name} />
          </div>
          <div className={styles.copy}>
            <h2>{recipe.name}</h2>
            <h3>ingredients</h3>
            <p>{recipe.ingredients}</p>
            <p>{recipe.directions}</p>
          </div>
    </div>
  )
}
;

Recipe.propTypes = {};

Recipe.defaultProps = {};

export default Recipe;
